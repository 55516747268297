import {isMobile} from 'react-device-detect';
import {useEffect, useState} from "react";



export default function useDevice () {
	const [_isMobile, setIsMobile] = useState(window.innerWidth <= 1080 || isMobile);
	useEffect(()=>{
		window.addEventListener('resize',()=>{
			if (window.innerWidth <= 1080) {
				setIsMobile(true)
			} else {
				setIsMobile(isMobile)
			}
		})
	}, [])

	return {
		isMobile: _isMobile
	}
}