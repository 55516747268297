import React, {useEffect} from 'react';
import Styled from "styled-components"
import { Swiper, SwiperSlide } from 'swiper/react';
import securityImg from 'assert/images/security.png'
import AOS from 'aos'
import {headerHeight} from "lib";
import ReactFullpage from "@fullpage/react-fullpage";
import logo from "assert/images/logo.png";
import multiplex_logo from "assert/images/multiplex_logo.png";
import {mediaList} from "components/header";
const Security = Styled.div`
  width: 100%;
  height: 100%;
	display:flex;
	flex-direction: column;
	align-items: center;
	.fp-overflow {
		padding-top:0!important;
		padding-bottom:80px!important;
	}
	h2 {
		font-size: 62px;
		font-family: Montserrat-Regular, Montserrat;
		font-weight: 400;
		color: #FFFFFF;
		position:relative;
		z-index:123;
		line-height: 76px;
		em {
			font-family: Montserrat-Regular, Montserrat;
			font-style: normal;
		}
	}
	dl {
		display: flex;
		flex-wrap: wrap;
		min-width: 100%;
		min-height: 100%;
		align-items: center;
		justify-content: center;
		dt {
			width: 100%;
			margin-bottom:50px;
			position: relative;
			overflow: hidden;
			display: flex;
			justify-content: center;
			background:#0F1012;
			padding-top:${headerHeight+100}px;
			padding-bottom:100px;
			flex-direction: column;
			h2 {
				text-align: center;
				height: 76px;
				margin-bottom:40px;
			}
			img {
				max-width:100%;
				height: 100%;
				max-height:727px;
				object-fit: contain;
			}
		}
		dd {
			width: 385px;
			height: 375px;
			background: #242429;
			border-radius: 10px;
			margin-right:40px;
			padding: 40px 48px;
			margin-bottom: 40px;
			h4 {
				font-size: 32px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 39px;
				margin-bottom: 20px;
			}
			p {
				font-size: 18px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 22px;
				margin-bottom: 10px;
			}
			ol {
				font-size: 14px;
				font-family: Montserrat-Light, Montserrat;
				font-weight: 300;
				color: #939393;
				line-height: 18px;
				margin-bottom: 20px;
			}
		}
	}
	@media (max-width:1080px) {
		&.section.fp-section {
			.fp-overflow {
				padding-top:0!important;
				padding-bottom:0!important;
			}
		}
		dl {
			width:100%;
			padding-bottom: 0;
			dt {
				display: block;
				height: auto;
				margin-top:0;
				padding-top:80px;
				div {
					position: relative;
					top:0;
					height: 220px;
				}
				img {
					height: auto;
					width: 120%;
					object-fit:cover;
					top:0;
					max-height: none;
					min-height: 220px;
				}
				h2 {
					font-size: 46px;
					line-height: 42px;
					width: 200px;
					margin-bottom:32px;
					padding:0 20px;
					height: auto;
					em {
						display: none;
					}
				}
			}
			dd {
				width:calc(100% - 40px);
				margin-right:0;
				margin-bottom: 20px;
				padding: 40px 30px;
			}
		}
	}
`

export default (props:any) => {
	return (
		<Security {...props}>
			<dl>
				<dt>
					<h2>
						Trusted <em>/</em> Secure <em>/</em> Infrastructures
					</h2>
					<img src={securityImg} />
				</dt>
				<dd>
					<h4>1.SENTRY</h4>
					<p>Datacenter:</p>
					<ol>
						<li>• Amazon AWS</li>
						<li>• Google Cloud</li>
						<li>• Private Datacenter</li>
					</ol>
					<p>Security:</p>
					<ol>
						<li>•	Front-end Firewall</li>
						<li>•	Back-end Firewall</li>
						<li>•	DDoS protection</li>
						<li>•	Hardening Server Security</li>
						<li>•	Low latency with High-Performance VPN</li>
					</ol>
				</dd>
				<dd>
					<h4>2.VALIDATOR</h4>
					<p>Security:</p>
					<ol>
						<li>• AS Carrier/ISP</li>
						<li>• Front-end Firewall</li>
						<li>• Full Layer7 inspection</li>
						<li>• IPS/IDS</li>
						<li>• DDoS protection</li>
						<li>• Back-end Firewall</li>
						<li>• Hardening Server Security</li>
						<li>• Low latency with High-Performance VPN</li>
						<li>• No incoming connections</li>
						<li>• KMS/HSM hardware</li>
					</ol>
				</dd>
				<dd>
					<h4>3.MONITORING</h4>
					<ol>
						<li>• Monitoring and alarming <br /> &nbsp;&nbsp;are performed on the datacenter, <br /> &nbsp;&nbsp;hardware, and virtual machines</li>
						<li>• Blockchain monitoring<br/> &nbsp;&nbsp;systems</li>
						<li>• Hourly reports</li>
						<li>• Real-time alarms</li>
						<li>• 24/7 monitoring</li>
					</ol>
				</dd>
			</dl>
		</Security>
	);
}

