import React, {useEffect, useState} from 'react';
import classnames from "classnames";
import LogoImg from "assert/images/logo.png"
import Discord from "assert/images/discord.png"
import Twitter from "assert/images/twitter.png"
import Email from "assert/images/mail.png"
import Styled from "styled-components"
import useDevice from 'hooks/useDevice';
import {headerHeight} from "lib";

export const mediaList = [
	{name: "Twitter",title: 'Twitter', url: "https://twitter.com/MultiplexLabs", image: Twitter},
	{name: "Discord",title: 'Discord', url: "https://discord.gg/eARSEBz5M3", image: Discord},
	{name: "Email",title: 'Business@multiplexlabs.xyz', url: "mailto:business@multiplexlabs.xyz", image: Email},
]
export const RouterList = [
	{name: "Home", route: "#home"},
	{name: "About", route: "#about"},
	{name: "Networks", route: "#networks"},
	{name: "Security", route: "#security"},
	// {name: "Partner", route: "#partner"},
	{name: "Contact", route: "#contact"},
]
const Img = Styled.img`
	width: 202px;
	@media (max-width:1080px) {
		width: 121px;
		font-size:12px;
	}	
`
const Logo = Styled.a`
	display: flex;
	flex-direction: column;
	margin-right: 146px;
	span {
		margin-top: 10px;
	}
	@media (max-width:768px) {
		font-size:12px;
		margin-right:0;
		span {
			margin-top:0;
		}
	}	
`
const Header = Styled.div`
	height:${headerHeight}px;
	width:100%;
	padding: 40px 20px;
	position: relative;
	position:fixed;
	z-index:1234;
	top:0;left:0;bottom:0;right:0;
	padding:0 20px;
	backdrop-filter: blur(4px);
  background: transparent 0% 0% no-repeat padding-box;
  margin: 0 auto;
  header {
    	max-width:1366px;
    	width: 100%;
			margin: 0 auto;
				display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;

  }
	.menu-toggle {
		&.open {
			position: fixed;
			z-index: 123457;
			right:20px;
			top: 26px;
		}
	}
	section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		&.isMobile{
			width: 83.2%;
			right: -100vw;
			height: 100vh;
			position: fixed;
			top:0;
			transition: right .5s;
			background: #242429;
			z-index:123456;
			justify-content:flex-start;
			align-items: flex-start;
			padding: 100px 40px 40px;
			&.open {
				right:0;
				transition: right .5s;
			}
			ul {
				display: flex;
				flex-direction: column;
				margin-left:0;
				width: 100%;
				justify-content: flex-start;
				li {
					height: 22px;
					margin-bottom: 44px;
					justify-content: flex-start;
					position: relative;
					width:100%;
					transform-origin:left;
					a {
						width: 100%;
						text-align: left;
						transform-origin:left;
					}
				}
			}
			dl {
				margin-left: 0;
				margin-top:auto;
			}
			display: flex;
			flex-direction: column;
		}
	}
	ul {
		display: flex;
		li {
			margin-right: 40px;
			a {
				display:block;
				text-align: center;
				width: 100%;
				height: 24px;
				font-family: Montserrat-Regular, Montserrat;
				line-height: 24px;
				transform: scale(1);
				transition: all .2s;
				min-width: 80px;
				font-size: 16px;
				transform-origin:center;
				&.active,&:hover {
					transform: scale(1.5);
					transition: transform .2s;
					color: #fff;
				}
			}
		}
	}
	dl {
		display: flex;
		dd {
			margin-right: 30px;
			img {
				height: 20px;
				transform:scale(1);
				transition:all 0.2s;
			}
			&:hover {
				img {
					transform:scale(1.4);
					transition:all 0.2s;
				}
			}
			&:last-child {
				margin-right:0;
			}
		}
	}
	@media (max-width:1080px) {
		height: 60px;
		padding: 0 20px;
		justify-content:space-between;
		header {
			height: 60px;		
		}
	}	
`
export default ({anchors}:any) => {
	const {isMobile} = useDevice();
	const [open, setOpen] = useState<undefined | boolean>(undefined)
	useEffect(()=>{
		document.addEventListener("click", (e:MouseEvent)=>closeMenu(e))
		return document.removeEventListener("click", (e:MouseEvent)=>closeMenu(e))
	},[]);
	const closeMenu = (e:MouseEvent) => {
		e.stopPropagation();
		setOpen(false);
		return false;
	}
	return (
		<Header>
			<header>
				<Logo href={"/"}>
					<Img src={LogoImg} alt="Logo"/>
					{/*<span>It's time to stake</span>*/}
				</Logo>
				{isMobile ? <button key={`${open}`} className={classnames(['menu-toggle', open !== undefined && {open: open, close: !open}])} onClick={(e:any)=>{e.stopPropagation();setOpen(!open);return false}} >
					<div className="menu-line"></div>
					<div className="menu-line"></div>
					<div className="menu-line"></div>
				</button>:null }
				<section className={classnames([{isMobile, open}])} onClick={(e:any)=>{e.stopPropagation();return false;}}>
					<ul>
						{
							RouterList.map((item)=> <li key={item.name} onClick={(e:any)=>{e.stopPropagation();setOpen(false);return false;}}>
								<a href={item.route} className={classnames([{active: anchors == item.route.split("#").join("")}])} >{item.name}</a>
							</li>)
						}
					</ul>
					<dl>
						{
							mediaList.map(item=><dd key={item.name}><a href={item.url} target={"_blank"}><img src={item.image} /></a></dd>)
						}
					</dl>
				</section>
			</header>
		</Header>
	);
}

