import React, {useEffect, useMemo, useRef, useState} from 'react';
import Styled from "styled-components"
import { Swiper, SwiperSlide } from 'swiper/react';
import CountUp from 'react-countup'
import BannerImg from "../../assert/images/banner.png";
import ArrowImg from "../../../assert/images/arrow.png";
import AOS from 'aos'
import securityImg from "assert/images/securityImg.png"
import ecosystemImg from "assert/images/ecosystemImg.png"
import firstImg from "assert/images/firstImg.png"
import Home from "../home";
import Networks from "../networks";
import Security from "../security";
import Contact from "../contact";
import ReactFullpage from "@fullpage/react-fullpage";
import {RenderCounterProps} from "react-countup/build/types";
import useDevice from "../../../hooks/useDevice";

const About = Styled.div`
	display:flex;
	flex-direction: column;
	align-items: center;
	text-align:center;
	.fp-overflow {
		padding-bottom:80px!important;
	}
	h2 {
		margin-bottom:50px;
		font-size: 82px;
		flex-shrink:0;
		font-family: Montserrat-Regular, Montserrat;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 100px;
		position:relative;
		overflow:hidden;
		em {
			font-family: Montserrat-Regular, Montserrat;
			font-style: normal;
			font-size: 82px;
			flex-shrink:0;
		}
		&.aos-init {
			&:after {
				content : attr(data-title);
				font-family: Montserrat-Regular, Montserrat;
				display: block;
				height: 100%;
				color: #2E643C;
				position: absolute;
				top:0;
				width:0%;
		    overflow: hidden;
		    animation: title-spin 3s;
		    z-index:123;
		    text-align:left;
		    white-space:nowrap;
			}
		}
	}
	@keyframes title-spin {
	  0 {
	    width: 0%;
	  }
	  50% {
	    width: 100%;
	  }
	  100% {
	    width: 0%;
	  }
	}
	p {
		max-width:1125px;
		margin-bottom:183px;
		font-size: 28px;
		font-family: Montserrat-Light, Montserrat;
		font-weight: 300;
		color: #939393;
		line-height: 34px;
	}
	.about-data{
		width: 100%;
		height: 178px;
		background: #242429;
		margin-bottom: 80px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		flex-shrink:0;
		ul {
			max-width:1000px;
			width:100%;
			height: 100%;
			padding-top: 34px;
			display: flex;
			justify-content: space-between;
			text-align: left;
			margin:0 auto;
			li {
				&:last-child{
					margin-right:0;
				}
			}
			p {
				font-size: 18px;
				font-family: Montserrat-Light, Montserrat;
				font-weight: 300;
				color: #FFFFFF;
				line-height: 50px;
				margin-bottom:0;
				text-align: left;
				max-width: 266px;
				width: 100%;
				white-space: nowrap;
			}
			span {
				font-size: 56px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 50px;
			}
		}
	}
	
	dl {
		display:flex;
		dd {
			width: 387px;
			height: 387px;
			border-radius:50%;
			background: #242429;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 35px;
			text-align: center;
			margin-right: 22px;
			img {
				height: 40px;
				width: 40px;
				flex-shrink:0;
			}
			p {
				margin: 10px 0 20px;
				color: #FFFFFF;
				white-space: nowrap;
				font-size: 24px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 29px;
			}
			span {
				font-size: 16px;
				font-family: Montserrat-Light, Montserrat;
				font-weight: 300;
				color: #939393;
				line-height: 19px;
			}
			&:last-child {
				margin-right:0;
			}
			&:first-child {
				margin-top:0;
			}
		}
	}
	@media (max-width:1240px) {
		dl {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	@media (max-width:1080px) {
		min-height: 100vh;
		align-items: flex-start!important;
		.fp-overflow {
			padding-bottom: 0 !important;
		}
		h2 {
			font-size: 58px;
			width: 180px;
			text-align: left;
			line-height: 52px;
			margin-bottom:20px;
			padding:0 20px;
			em {
				display: none;
			}
			&.aos-init{
				&:after{
			    white-space:normal;
				}			
			}

		}
		p{
			font-size: 16px;
			line-height: 20px;
			margin-bottom:62px;
			text-align: left;
			padding:0 20px;
			font-weight: 300;
		}
		.about-data{
			padding:0;
			background: none;
			height: auto;
			margin-bottom: 24px;
			ul {
				background: none;
				flex-direction:column;
				align-items: flex-start;
				height: auto;
				max-width:100%;
				padding:0;
				li {
					width:100%;
					align-items: flex-start;
					justify-content: flex-start;
					margin:0 0 20px 0;
					text-align: left;
					p {
						font-size: 14px;
						line-height: 18px;
						margin-bottom:8px;
						width:100%;
					}
					span {
						display:flex;
						align-items: center;
						width: 100%;
						height: 44px;
						background: #242429;
						font-size: 32px;
						line-height: 39px;
						padding:0 20px;
					}
				}
			}
		}
		dl {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items:center;
			dd {
				width: 330px;
				height: 330px;
				max-width: 330px;
				margin-right:0;
				margin-top:0;
				margin-bottom: 20px;
				&:last-child {
					margin:0;
				}
				p {
					font-size: 18px;
					font-family: Montserrat-Regular, Montserrat;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 22px;
				}
				span {
					font-size: 12px;
					font-family: Montserrat-Light, Montserrat;
					font-weight: 300;
					color: #939393;
					line-height: 15px;
				}
			}
		}
	}
`

export default (props:any) => {
	const [show, setShow] = useState(false)
	const {isMobile} = useDevice();
	const networks = 8;
	useEffect(()=>{
		console.log(props.anchors,'-props.anchors')
		if (props.anchors === "about") {
			setShow(true)
		}else {
			setShow(false)
		}
	}, [props.anchors])
	const aosObj = useMemo(()=>{
		if (isMobile) {
			return {}
		} else {
			return {
				"data-aos": "fade-up",
				"data-aos-easing": "ease-in-sine",
				"data-aos-offset": "0"
			}
		}
	}, [isMobile]);
	return (
		<About {...props}>
			<h2 className={show?"aos-init":''} data-title={isMobile?"Stake  Earn  Build":"Stake / Earn / Build"}>
				Stake <em>/</em> Earn <em>/</em> Build
			</h2>
			<p>Multiplex is a leading, highly-secure validator node operator trusted on {networks} networks and chosen by users worldwide. Secure your Proof of Stake assets, manage your yields more efficiently, and build ecosystems.</p>
			<div className={"about-data"}>
				<ul>
					<li>
						<p>Total assets staked</p>
						{show ? <CountUp
							end={1900000}
							prefix={"$"}
							separator=","
						/>:<span /> }
					</li>
					<li>
						<p>Total networks</p>
						{show ? <CountUp
							end={networks}
							separator=","
						/>:<span /> }
					</li>
					<li>
						<p>Uptime</p>
						{show ? <CountUp
							end={99.9}
							decimals={1}
							separator=","
							suffix={"%"}
						/>:<span /> }
					</li>
				</ul>
			</div>
			<dl>
				<dd {...aosObj} data-aos-duration={500}>
					<img src={securityImg} />
					<p>State-of-the-art security</p>
					<span>
						Our validator nodes are protected by an impenetrable structure. 24/7 monitoring of malicious traffic entering and leaving our nodes.
					</span>
				</dd>
				<dd {...aosObj} data-aos-duration={800}>
					<img src={ecosystemImg} />
					<p>Boost the ecosystem</p>
					<span>
						As a provider of end-user applications like Falcon relayers and RPC, Multiplex contributes to the security of networks.
					</span>
				</dd>
				<dd {...aosObj} data-aos-duration={1200}>
					<img src={firstImg} />
					<p>Delegators come first</p>
					<span>
						At Multiplex, we operate for and with the community in mind. With 99.9% uptime, Multiplex offers high & stable staking rewards.
					</span>
				</dd>
			</dl>
		</About>
	);
}

