import React, {useEffect, useMemo, useState} from 'react';
import Styled from "styled-components"
import classnames from "classnames"
import cosmos from "assert/images/cosmos.png";
import cosmos_active from "assert/images/cosmos_active.png";
import evmos from "assert/images/evmos.png";
import evmos_active from "assert/images/evmos_active.png";
import stride from "assert/images/stride.png";
import stride_active from "assert/images/stride_active.png";
import canto from "assert/images/canto.png";
import canto_active from "assert/images/canto_active.png";
import osmosis from "assert/images/osmosis.png";
import stargaze from "assert/images/stargaze.svg";
import osmosis_active from "assert/images/osmosis_active.png";
import nibi from "assert/images/nibi.svg";
import persistence from "assert/images/persistence.svg";
import AOS from 'aos'
import ShuffleText from "shuffle-text"
import ReactFullpage from "@fullpage/react-fullpage";
import logo from "../../../assert/images/logo.png";
import multiplex_logo from "../../../assert/images/multiplex_logo.png";
import {mediaList} from "../../../components/header";

import useDevice from "../../../hooks/useDevice";
const Networks = Styled.div`
	padding: 128px 0 0;
	display:flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	.fp-overflow {
		padding-bottom: 80px!important;;
	}
	h2 {
		margin-bottom:80px;
		font-size: 82px;
		flex-shrink:0;
		font-family: Montserrat-Regular, Montserrat;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 100px;
		position: relative;
	  .text {
	    opacity: 0;
	  }
	  &.staggered_bar_cross {
	    .text {
	      animation: fade-in 0.2s 0.6s ease-out forwards;
	    }
	  }
	  .bars {
	    $bars-dalay: 0.3s, 0.2s, 0.5s, 0.3s, 0.4s;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    display: flex;
	    flex-direction: column;
	    overflow: hidden;
	    .bar {
	      width: 100%;
	      flex: 1;
	      background: currentColor;
	      animation: 0.6s cubic-bezier(0.4, 0.2, 0.175, 1) forwards;
	      &:nth-child(odd) {
	        transform: translateX(100%);
	        animation-name: slide-left;
	      }
	      &:nth-child(even) {
	        transform: translateX(-100%);
	        animation-name: slide-right;
	      }
	      @for $i from 1 through 5 {
	        &:nth-child(#{$i}) {
	          animation-delay: nth($bars-dalay, $i);
	        }
	      }
	    }
	  }
		
		@keyframes slide-left {
		  to {
		    transform: translateX(-110%);
		  }
		}
		
		@keyframes slide-right {
		  to {
		    transform: translateX(110%);
		  }
		}
		
		@keyframes fade-in {
		  to {
		    opacity: 1;
		  }
		}
	}
	dl {
		display: flex;
		flex-wrap: wrap;
		max-width: 1040px;
		margin-left: 20px;
		dt {
			width: 100%;
			font-size: 32px;
			font-family: Montserrat-Regular, Montserrat;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 39px;
			margin:40px 0 20px;
		}
		dd {
			width: 240px;
			height: 291px;
			background: #242429;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-right: 20px;
			margin-bottom: 20px;
			img {
				width: 60px;
				height: 60px;
				&.hover {
					display: none;
				}
			}
			span {
				font-size: 16px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 19px;
				margin-top:40px;
			}
			&.upcoming {
				border-radius: 10px;
				border: 2px dashed #808080;
				background: none;
				&:hover {
					background: none;
					span {
						color: #fff;
					}
				}
			}
			a {
				width: 200px;
				height: 0px;
				overflow: hidden;
				border-radius: 4px;
				font-size: 16px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				line-height: 19px;
				align-items: center;
				justify-content: center;
				margin-top: 50px;
				display: flex;
				transition: height 0.5s;
				cursor: pointer;
				border: 1px solid #242429;
				background: #c8c9cc;
				cursor:no-drop;
				opacity:.8;
				color:#fff;
				&.active {
					color: #242429;
					opacity:1;
					cursor: pointer;
					background:transparent;
					&:hover {
						background: #2E643C;
						border-color: #2E643C;
						color: #fff;
					}
				}
			}
			&:hover {
				background: #FFFFFF;
				span {
					font-size: 16px;
					font-weight: 400;
					color: #242429;
					line-height: 19px;
				}
				img {
					display: none;
					&.hover {
						display:block;
					}
				}
				a {
					height: 46px;
					transition: height 0.5s;
					border-color: #fff;
					&.active {
						border-color:#242429;
					}
				}
			}
		}
	}
	@media (max-width:1080px) {
		padding-left: 20px;
		padding-right: 20px;
		.fp-overflow {
			padding-bottom: 0!important;;
		}
		h2 {
			font-size: 58px;
			line-height: 52px;
			width: 300px;
			text-align: left;
			margin-bottom:40px;
		}
		dl {
			width: 100%;
			margin-left:0;
			dd {
				width: 100%;
				margin-right: 0;
				margin-bottom:20px;
				a {
					height: 46px;
					border: 1px solid #FFFFFF;
					color:#fff;
				}
				&:hover {
					background: #242429;
					span {
						color: #fff;
					}
					img {
						display:block;
						&.hover {
							display: none;
						}
					}
				}
			}
		}
	}
`
const list = [
	{name: "Cosmos",active: true, "image": cosmos, "hover_image": cosmos_active,url: "https://wallet.keplr.app/chains/cosmos-hub?modal=validator&chain=cosmoshub-4&validator_address=cosmosvaloper1a4qlael79p76my9pml6thwhnnzsxyy4ajrvd9s"},
	{name: "Evmos",active: true, "image": evmos, "hover_image": evmos_active,url: "https://wallet.keplr.app/chains/evmos?modal=validator&chain=evmos_9001-2&validator_address=evmosvaloper1dj0mysdn05447p9ncsq9g424fv2ynaxryhpjkc"},
	{name: "Osmosis",active: true, "image": osmosis, "hover_image": osmosis_active,url: "https://wallet.keplr.app/chains/osmosis?modal=validator&chain=osmosis-1&validator_address=osmovaloper182d2cjt4al2qxkrgx2kp3v8rvm2jc50ft8wz57"},
	{name: "Stargaze",active: true, "image": stargaze, "hover_image": stargaze,url: "https://wallet.keplr.app/chains/stargaze?modal=validator&chain=stargaze-1&validator_address=starsvaloper14f0uuyvumwx9ml6vflk3zx8tsfhzxefq8m4z22"},
	{name: "Stride",active: true, "image": stride, "hover_image": stride_active,url: "https://wallet.keplr.app/chains/stride?modal=validator&chain=stride-1&validator_address=stridevaloper1w32vrtn97e0cl6lmayux07g37v5jgt6tejze9h"},
	{name: "Canto",active: true, "image": canto, "hover_image": canto_active,url: "https://staking.canto.io/"},
	{name: "Persistence",active: true, "image": persistence, "hover_image": persistence,url: "https://wallet.keplr.app/chains/persistence?modal=validator&chain=core-1&validator_address=persistencevaloper1rjfcgqrgmlmrptramcjy7kymnc5k6j7qet5spr"},
	{name: "Nibiru",active: false, "image": nibi, "hover_image": nibi,url: "https://nibiru.fi/"},
]
export default (props:any) => {
	const [titleAnimation,setTitleAnimation] = useState(false);
	const {isMobile} = useDevice();

	useEffect(()=>{
		let staggered_bar_cross = document.querySelector("#staggered_bar_cross");
		if (staggered_bar_cross && props.anchors === "networks") {
			staggered_bar_cross.classList.add("staggered_bar_cross");
			let staggeredBarCrossTexts = document.querySelectorAll(".staggered_bar_cross");
			staggeredBarCrossTexts.forEach(staggeredBarCrossText => {
				let bars = document.createElement("div");
				bars.className = "bars";
				for (let i = 0; i < 5; i++) {
					let bar = document.createElement("div");
					bar.className = "bar";
					bars.append(bar);
				}
				staggeredBarCrossText.append(bars);
			});
		} else {
			if (staggered_bar_cross) {
				staggered_bar_cross.classList.remove("staggered_bar_cross");
				let bars = staggered_bar_cross.querySelector(".bars");
				if (bars) {
					staggered_bar_cross.removeChild(bars);
				}
			}
		}

	}, [props.anchors])
	const aosObj = useMemo(()=>{
		if (isMobile) {
			return {}
		} else {
			return {
				"data-aos":"fade-up",
				"data-aos-easing":"ease-in-sine"
			}
		}
	}, [isMobile])
	return (
		<Networks {...props}>
			<h2 id={"staggered_bar_cross"}><div className={"text"}>Stake with Multiplex</div></h2>
			<dl>
				{
					list.map((item, index)=><dd key={item.name} {...aosObj} data-aos-offset="0" data-aos-duration={1000 + (index+1)*100}><img src={item.image}/><img className={"hover"} src={item.hover_image}/><span>{item.name}</span><a href={item.active ? item.url: 'javascript:void(0);'} className={classnames([{active: item.active}])} target={item.active ?"_blank":"_self"}>Delegate</a></dd>)
				}
				{/*<dt>Upcoming</dt>*/}
				{/*<dd className={"upcoming"} {...aosObj} data-aos-offset="100" data-aos-duration={(list.length + 2)*100+1000}><span>Coming soon...</span></dd>*/}
				{/*<dd className={"upcoming"} {...aosObj} data-aos-offset="100" data-aos-duration={(list.length + 2)*100+1000}><img src={osmosis}/><img className={"hover"} src={osmosis_active}/><span>Osmosis</span></dd>*/}
			</dl>
		</Networks>
	);
}

