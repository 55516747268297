import React, {useEffect} from 'react';
import Header from "./components/header";
import Footer from "./components/footer";
import "./App.scss";
import AOS from 'aos'
import 'aos/dist/aos.css'
import Index from "pages/index";
export default function App() {

  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <>
        <Index />
    </>
);
}
