import React, {useEffect} from 'react';
import Styled from "styled-components"
import logo from "assert/images/logo.png";
import multiplex_logo from "assert/images/multiplex_logo.png";
import ArrowImg from "assert/images/arrow.png";
import AOS from 'aos'
import {mediaList} from "components/header";
import {Swiper, SwiperSlide} from "swiper/react";
import ReactFullpage from "@fullpage/react-fullpage";
import CountUp from "react-countup";
import securityImg from "../../../assert/images/securityImg.png";
import ecosystemImg from "../../../assert/images/ecosystemImg.png";
import firstImg from "../../../assert/images/firstImg.png";
import useDevice from "../../../hooks/useDevice";

const Contact = Styled.div`
	display:flex;
	flex-direction: column;
	align-items: center;
	.fp-overflow {
			padding-bottom: 60px;
	}
	img {
		&.logo {
			width: 100%;
			max-width: 348px;
			padding:0 20px;
		}
		&.multiplex_logo{
			width: 100%;
			max-width: 600px;
		}
	}
	p {
		font-size: 18px;
		font-family: Montserrat-Light, Montserrat;
		font-weight: 300;
		color: #939393;
		line-height: 22px;
		margin-bottom:70px;
		max-width:762px;
		margin-top:20px;
		text-align: center;
	}
	h4 {
		font-size: 32px;
		font-family: Montserrat-Regular, Montserrat;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 39px;
		margin-top: 60px;
	}
	a {
		font-size: 16px;
		font-family: Montserrat-Light, Montserrat;
		font-weight: 300;
		color: #FFFFFF;
		line-height: 19px;
		text-decoration: underline;
		margin-top: 20px;
	}
	dl {
		display: flex;
		flex-wrap:wrap;
		justify-content: center;
		margin-top: 60px;
		dt {
			margin-bottom: 20px;
			width: 100%;
			display: flex;
			justify-content: center;
			h5 {
				font-size: 32px;
				font-family: Montserrat-Regular, Montserrat;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 39px;
			}
		}
		dd {
			margin-right:44px;
			&:last-child {
				margin-right:0;
			}
		}
	  a {
	    display: flex;
	    align-items: center;
	    margin:0;
	    text-decoration: none;
	  }
		img {
			height: 28px;
			margin-right: 10px;
		}
	}
	@media (max-width:1080px) {
		padding-left: 20px;
		padding-right: 20px;
		min-height: 100vh;
		.fp-overflow {
			align-items: center!important;
		}
		p{
			padding:0 20px;
		}
		img {
			&.multiplex_logo {
				padding:0 20px;
			}
		}
		dl {
			margin-top: 40px;
			img {
				width: 24px;
				margin-right: 8px;
			}
			dd {
				margin-right: 26px;
			}
		}
	}
`

export default (props:any) => {
	const {isMobile} = useDevice();
	return (
		<Contact {...props}>
			<img src={logo} className={"logo"} />
			<p>Multiplex is a research-driven lab focusing on building decentralized infrastructure and all on a mission to shape the future of web3</p>
			<img src={multiplex_logo} className={"multiplex_logo"} />
			<h4>Mail Us</h4>
			<a href={mediaList[2].url} target={"_blank"}>{mediaList[2].title}</a>
			<dl>
				<dt>
					<h5>Community</h5>
				</dt>
				{
					mediaList.map(item=> (<dd key={item.name}><a href={item.url} target={"_blank"}><img src={item.image} />{item.name}</a></dd>))
				}
			</dl>
		</Contact>
	);
}

