import React, {useState} from "react";
import ReactFullpage, {Item} from '@fullpage/react-fullpage';
import Styled from "styled-components"
import "swiper/css"
import 'swiper/css/virtual';
import Home from "./home";
import AOS from "aos";
import About from "./about";
import Networks from "./networks";
// import Partner from "./partner";
import Security from "./security";
import Contact from "./contact";
import {headerHeight} from "lib";
import Header from "components/header";
import useDevice from "../../hooks/useDevice";
const IndexBox = Styled.div`
    width: 100%;
    height: 100%;
    .section { 
      min-height:100vh!important;
      padding-top:0;
      padding-bottom:0;
			display:flex;
			flex-direction: column;
			align-items: center;
			.fp-overflow {
				display:flex;
				flex-direction: column;
				align-items: center;
				padding-top: 200px;
			}	
    }
    @media(max-width:1080px) {
      overflow: auto;
      height: auto!important;
      .section {
        height:auto!important;
        min-height:auto!important;
        .fp-overflow {
          align-items: flex-start;
          padding-top: 100px!important;
        }
      }
    }
`
/*

			onSlideChangeTransitionStart={(e) => {
				console.log(e,'---e')
				try {
					const slide = e.slides[e.activeIndex];
					let aosInits = slide.querySelectorAll(".aos-init");
					aosInits.forEach((item)=>{
						item.classList.remove("aos-animate")
						item.classList.remove("aos-init")
					})
					AOS.init();
				} catch (e) {}
			}}
			onSlideChangeTransitionEnd={(e)=>{
				try {
					const slide = e.slides[e.activeIndex];
					let aosInits = slide.querySelectorAll(".aos-init");
					aosInits.forEach((item)=>{
						item.classList.add("aos-animate")
					})
					AOS.init();
				} catch (e) {}
			}}
* */
function Index({anchors}:any) {
	return (<IndexBox>
		<Home className="section" anchors={anchors} data-history={"home"} />
		<About className="section" anchors={anchors} data-history={"about"} data-is-aos />
		<Networks className="section" anchors={anchors} data-history={"networks"} data-is-aos />
		{/*<Partner className="section"  anchors=anchorsdata-history={"partner"} data-is-aos />*/}
		<Security className="section" anchors={anchors} data-history={"security"} data-is-aos />
		<Contact className="section" anchors={anchors} data-history={"contact"} />
	</IndexBox>)
}
export default () => {
	const {isMobile} = useDevice();
	const [anchors, setAnchors] = useState<string | undefined | null | number>("home");
	return (
			<>
			<Header anchors={anchors} />
				<ReactFullpage
					anchors={["home", "about","networks", "security", "contact"]}
					debug={false}
					scrollingSpeed = {1000}
					touchSensitivity={2000}
					loopHorizontal={false}
					fitToSection={false}
					resetSliders={true}
					scrollOverflowReset={true}
					paddingBottom={"0px"}
					autoScrolling={!isMobile}
					afterLoad={( origin: Item, destination: Item, direction: string) => {
						if (origin?.item?.dataset?.isAos) {
							let aosInits = origin?.item.querySelectorAll(".aos-init");
							aosInits.forEach((item)=>{
								item.classList.remove("aos-animate")
								item.classList.remove("aos-init")
							})
							AOS.init();
						};
						if (destination?.item?.dataset.isAos) {
							let aosInits = destination?.item.querySelectorAll(".aos-init");
							aosInits.forEach((item)=>{
								item.classList.add("aos-animate")
							})
							AOS.init();
						}
						setAnchors(destination.anchor)
						console.log(direction, '--direction-afterLoad');
					}}
					recordHistory={true}
					onLeave={( origin: Item, destination: Item, direction: string) => {
						console.log(origin,"--origin-onLeave");
						console.log(destination,"--destination-onLeave");
						console.log(direction, '--direction-onLeave');
					}}
					onSlideLeave={( section: Item, origin: Item, destination: Item, direction: string) => {
						console.log(section,"--section-onSlideLeave");
						console.log(origin,"--origin-onSlideLeave");
						console.log(destination, '--destination-onSlideLeave');
						console.log(direction, '--direction-onSlideLeave');
					}}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<Index anchors={anchors} />
							</ReactFullpage.Wrapper>
						);
					}}
				/>
		</>
	)
}