import React, {useEffect, useState} from 'react';
import Styled from "styled-components"
import useTypewriter from "react-typewriter-hook"
import Particles from "react-particles";
import type { Engine } from "tsparticles-engine";
import { loadLinksPreset } from "tsparticles-preset-links";
import HomeBanner from "assert/images/landing_banner.png";
import multiplex_logo from "assert/images/multiplex_logo.png";
import ArrowImg from "assert/images/arrow.png";
import AOS from 'aos'
import {headerHeight} from "lib";
import useDevice from "hooks/useDevice";
import {Swiper, SwiperSlide} from "swiper/react";
import ReactFullpage from "@fullpage/react-fullpage";
import logo from "../../../assert/images/logo.png";
import {mediaList} from "../../../components/header";
const Home = Styled.div`
	overflow: hidden;
	height: 100%;
	display:flex;
	flex-direction: column;
	justify-content:center;
	align-items:center;
	#tsparticles{
		position: absolute;
		top:0;left:0;
		width:100%;height:100%;
		z-index:2;
		opacity:0.2;
	}
	.fp-overflow {
		max-width:1360px;
		margin:0 auto;
		width:100%;
		align-items: flex-start!important;
		justify-content:center;
		padding-top:0!important;
	}
	@keyframes show {
		0% {
			opacity:0.2;
		}
		100% {
			opacity:0;
		}
	}
	img {
		position:absolute;
		object-fit:contain;
		right:0;
		bottom:0;
		max-height: calc(100vh - ${headerHeight}px);
		object-position:left;
		z-index:1;
	}
	h2,h3 {
		font-size: 111px;
		font-family: Montserrat-Regular, Montserrat;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 135px;
		position:relative;
		z-index:123;
	}
	h3 {
		margin-top:-20px;
		height: 135px;
    &:after {
        display: inline-block;
        content: "";
				width: 28px;
				height: 96px;
				border-radius: 1px;
        background-color: #FFFFFF;
        margin-left: 30px;
        animation: 0.5s cursor infinite;
        transform: translateY(12px);
    }
    @keyframes cursor
    {
        0% {opacity: 0;}
        50% {opacity: 1;}
        100% {opacity: 0;}
    }
	}
	a {
		z-index:123;
		position:relative;
		margin-top: 40px;
		width: 220px;
		height: 46px;
		background: #2E643C;
		border-radius: 4px;
		font-size: 22px;
		font-family: Montserrat-Regular, Montserrat;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 27px;
		display: flex;
		justify-content: center;
		align-items: center;
	  overflow: hidden;
	  transform: scale(1);
	  transition: transform .2s;
    transform-origin: left;
		img {
			margin-left:20px;
			height: 30px;
			width: 30px;
			position:relative;
			min-width: 30px;
			min-height: 30px;
			transition: margin .2s;
		  // animation: lefting 1s linear infinite;
		}
		&:before, 
		&:after{
		  position: absolute;
		  content: '';
		  z-index: 10;
		  transition: all .5s;
		}
		&:before, 
		&:after, 
		& span:before, 
		& span:after{
		  background: linear-gradient(to right, rgba(0,0,0,0), #fff);
		  transition: all 2s;
		}
		&:before, 
		&:after{
		  width: 100%;
		  height: 2px;
		}
		&:before {
		  top: 0;
		  left: -100%;
		}
		&:after {
		  bottom: 0;
		  right: -100%;
		}
		span{
			&:before, &:after{
			  position: absolute;
			  content: '';
			  z-index: 10;
			  transition: all .5s;
			  width: 2px;
			  height: 100%;
			}
			&:before {
			  bottom: -100%;
			  left: 0;
			}
			&:after {
			  top: -100%;
			  right: 0;
			}
		}
		&:hover {
		  transform: scale(1.02);
		  transition: transform .2s;
			&:before{
			  animation: topA 1s linear infinite;
			  animation-delay: .5s;
			}
			&:after{
			  animation: bottomA 1s linear infinite;
			  animation-delay: 1.5s;
			}
			span {
				&:after{
				  animation: rightA 1s linear infinite;
				  animation-delay: 1s;
				}
				&:before {
				  animation: leftA 1s linear infinite;
				  animation-delay: 2s;
				}
			}
		}
		@keyframes topA{
		  100%{
		    left: 100%;
		  }
		}
		@keyframes rightA{
		  100%{
		    top: 100%;
		  }
		}
		@keyframes bottomA{
		  100%{
		    right: 100%;
		  }
		}
		@keyframes leftA{
		  100%{
		    bottom: 100%;
		  }
		}
	}
	
	@media (max-width:1080px) {
		padding-left: 20px;
		padding-right: 20px;
		width:100%;
		height: 100vh;
		align-items: flex-start;
		flex-direction: column;
		position:relative;
		top:0;left:0;
		margin:0;
		justify-content: flex-start;
		align-items: flex-start!important;
		h2, h3{
			font-size: 58px;
			height: 70px;
			line-height: 71px;
		}
		h2 {
			order: 1;
			margin-bottom:40px;
		}
		h3 {
			display: flex;
			&:after{
				height:60px;
				width: 10px;
				transform: translateY(10px);
			}
		}
		a {
			order: 3;
			img {
				margin:0 0 0 20px;
			}
		}
		img {
			position:relative;
			order: 2;
			width: 100%;
			margin:40px 0 50px;
			min-height:auto;
			object-fit: contain;
			object-position:center;
			min-width:100%;
		}
	}
	@media (max-width: 768px) {
		h2, h3{
			font-size: 48px;
		}
		h3 {
			&:after {
				margin-left: 20px;
			}
		}
	}
`
let textList:string[] = ["Developers", "Validators", "Builders", "Researchers", "Explorers"];
export default (props:any) => {
	const {isMobile} = useDevice();
	const [text, setText] = useState<string>(textList[0])
	const Title = useTypewriter(text)
	useEffect(()=>{
		setTimeout(()=>{
			// @ts-ignore
			const nowIndex = textList.findIndex((item) => item === text);
			if (nowIndex < textList.length - 1 && textList[nowIndex+1]) {
				setText(textList[nowIndex+1])
			} else {
				setText(textList[0])
			}
		}, 3000)
	},[text])

	return (
		<Home {...props}>
			{/*{*/}
			{/*	!isMobile?<Particles key={"links"} options={{preset: "links"}} init={async (engine:Engine)=>await loadLinksPreset(engine)} />:null*/}
			{/*}*/}
			<h2>We’re
				<h3 key={Title}>{Title}</h3>
			</h2>
			<a href={"#networks"}><span data-title={"Stake Now"}>Stake Now</span><img src={ArrowImg} /></a>
			<img src={isMobile?multiplex_logo:HomeBanner} />
		</Home>
	);
}

